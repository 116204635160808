import React from "react"
import ClientLogo from "../components/clientsLogo/clientsLogo";
import Header from "../components/header/header";
import Footer from "../components/footer/footer";
import styled from "styled-components";
import sliderInner  from "../images/slider/innerSlider.jpg";
import { useStaticQuery, graphql } from "gatsby";






export const GalleryWrapper = styled.div`
max-width: 960px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    background-color: #fff;
    box-shadow: 0px 5px 10px #ccc;
    min-height: 550px;
    padding: 15px;
    .gallery {
      display: flex;
      flex-wrap: wrap;
    }
    h2{
      color: #2e308f;
        margin-bottom: 20px;
    }
    .img-responsive{
      display: block;
      max-width: 100%;
      height: auto;
    }
    .image {
      flex: 1 0 21%; /* explanation below */
      margin: 5px;
    }
    
`;
const IndexPage = () => {
  const data = useStaticQuery(graphql`
    {
      allStrapiGallerySection {
        nodes {
          title
          images {
            image {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
          }
        }
      }
    }
  `)

const node = data.allStrapiGallerySection.nodes[0];
  return (
    <GalleryWrapper>
      <Header />
      <div className="inner-slider">
        <img src={sliderInner} className="img-responsive"></img>
      </div>
      <h2>{node.title}</h2>
      <div className="gallery-wrapper"> 
        <div className="gallery">
          {
            node.images.map(item =>{
              return <div className="image"><img src={item.image.childImageSharp.fluid.src}></img></div>
            })
          }
        </div>
      </div>
      <ClientLogo />
      <Footer />
    </GalleryWrapper>
  )
}

export default IndexPage
